import { template as template_4fa5c40a012045a6a2b563e1db845091 } from "@ember/template-compiler";
const SidebarSectionMessage = template_4fa5c40a012045a6a2b563e1db845091(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
