import { template as template_78c8ffd9acd54287bd6b0202d0fd8c00 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_78c8ffd9acd54287bd6b0202d0fd8c00(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
