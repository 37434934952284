import { template as template_38341d28ee974a8aaaaf5110eee4faea } from "@ember/template-compiler";
const FKLabel = template_38341d28ee974a8aaaaf5110eee4faea(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
